<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.7071 5.29295C22.0976 5.68348 22.0976 6.31664 21.7071 6.70717L9.70711 18.7072C9.31658 19.0977 8.68342 19.0977 8.29289 18.7072L2.29289 12.7072C1.90237 12.3166 1.90237 11.6835 2.29289 11.293C2.68342 10.9024 3.31658 10.9024 3.70711 11.293L9 16.5858L20.2929 5.29295C20.6834 4.90243 21.3166 4.90243 21.7071 5.29295Z"
      fill="currentColor"
    />
  </svg>
</template>
